import * as React from 'react';

export function replaceNewLinesWithBreaks(text: string): React.ReactElement {
    const lines = text.trim().split('\n');

    const elements = lines.reduce((elements, line, index) => {
        const breakElements = index > 0 ? [<br key={`${index - 1}-br`} />] : [];
        const lineElements = !!line ? [<React.Fragment key={index}>{line}</React.Fragment>] : [];

        return [...elements, ...breakElements, ...lineElements];
    }, [] as (React.ReactElement | React.ReactText)[]);

    return <React.Fragment>{elements}</React.Fragment>;
}
